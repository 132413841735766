<template>

  <div>
    <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>
    <!-- Table Container Card -->
    <b-card no-body>
    
      <b-card-body>
        <b-row>
          <b-col
            cols="12"
            md="4"
            class="mb-md-0 mobile_tab_max_width_flex">
            <!-- <label>Status</label> -->
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="sites"
              label="site_name"
              class="w-100"
              placeholder="Project Site"
              @input="filterTable"
              v-model="siteData"
              :clearable="false"
            >
            <template #selected-option="{ site_name }">
                <span v-if="site_name.length < 23">{{site_name}}</span>
                <span v-if="site_name.length >= 23">{{site_name.substring(0, 23) + '...'}}</span>
              </template>
            </v-select>
          </b-col>

          

          <!-- <b-col 
           cols="12"
           md="9"
           class="mb-md-0 mb-2"
          >

            <div class="d-flex align-items-center justify-content-end">
            </div>
          </b-col> -->
          
        </b-row>
      </b-card-body>
    </b-card>
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">
        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start  mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
              @input="filterTable"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
                @input="filterTable"
              />
              <b-button
                variant="warning"
                @click="openModel"
              >
                <span class="text-nowrap">Import</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>

      </div>
      <b-table sticky-header
        ref="refUserListTable"
        class="position-relative sTable"
        :items="dataList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        :class="items.length <= 10 ? 'remove_overflow' : 'add_overflow'"
      >

        <!-- Column: User -->
        <template #cell(site)="items">
           
            <span class="wordBreak">
              <div class="my-1">{{ items.item.site.site_name | capitalize}}</div>
            </span>
         
        </template>

        <!-- Column: Role -->
        <template #cell(form_name)="items">
           
            <span class="wordBreak">{{ items.item.form_name | capitalize}}</span>
         
        </template>

        <template #cell(unit_name)="items">
           
            <span class="wordBreak">{{ items.item.unit_name | capitalize}}</span>
         
        </template>

        <template #cell(element_name)="items">
           
            <span class="wordBreak">{{ items.item.element_name | capitalize}}</span>
         
        </template>

        

        
        
        <!-- <template #cell(status)="items">
           
            <b-badge
              pill
              :variant="variantColor(items.item.status)"
              class="text-capitalize"
            >
              {{ items.item.status}}
            </b-badge>
         
        </template>

        <template #cell(created_at)="items">
           
            <span>{{ items.item.created_at | dateTime}}</span>
         
        </template> -->

        <template #cell(actions)="items">

          <b-link 
            :to="{}"
            v-b-tooltip.hover.v-warning
            title="Delete"
            variant="outline-warning"
            @click="deleteItem(items.item._id)"
          >
              <feather-icon icon="Trash2Icon" class="mediumSize" />
              
          </b-link>

          <!-- <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template> -->

            

            <!-- <b-dropdown-item :to="{ name: 'edit-unit', params: { id: items.item._id } }">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="statusChange(items.item)">
              <feather-icon :icon="items.item.status == 'active' ? 'UserMinusIcon' : 'UserCheckIcon'" />
              <span class="align-middle ml-50" v-if="items.item.status == 'active'">Inactive</span>
              <span class="align-middle ml-50" v-if="items.item.status == 'inactive'">Active</span>
            </b-dropdown-item> -->

            <!-- <b-dropdown-item @click="deleteItem(items.item._id)">
              <feather-icon icon="Trash2Icon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>

            

            
          </b-dropdown>  -->
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalRecords"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
              @input="filterTable"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>

    <b-modal
        id="import"
        ref="import"
        cancel-variant="outline-secondary"
        ok-variant="outline-warning"
        ok-title="Submit"
        cancel-title="Close"
        centered
        title="Import Inspection Unit"
        @ok="uploadIU"
        
        size="lg"
        no-close-on-backdrop
      >

        <b-alert
          v-model="showDismissibleAlert"
          variant="danger"
          dismissible
          class="mb-1"
        >
          <div class="alert-body">
            {{error_message}}
          </div>
        </b-alert>

        <b-form>
          
          
          <b-row>
            <b-col
              cols="12"
              md="12"
            >
              <div class="d-flex align-items-center justify-content-end">
                
                <b-button
                  variant="danger"
                  align="right"
                  @click="sampleDownload"
                >
                  <span class="text-nowrap">Download Sample</span>
                </b-button>
              </div>
            </b-col>
          </b-row>
      
      
          <b-row>
            
            <b-col md="12">
              <b-form-group
                label="Project Site"
                
                class="required"
              >
                <b-form-select v-model="form.site" @change="updateForm()">
                  <b-form-select-option value="" disabled>Select</b-form-select-option>
                  <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>


            <b-col md="12">
              <b-form-group
                label="Form"
                class="required"
                
              >
                <b-form-select v-model="form.form">
                  <b-form-select-option value="" disabled>Select</b-form-select-option>
                  <b-form-select-option :value="f._id" v-for="f in forms" :key="f._id">{{f.form_name | capitalize}}</b-form-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>


            <b-col md="12">
              <b-form-group
                label="Upload File Template"
                
                class="required"
              >
                <b-form-file
                  id="upload_file"
                  ref="uploadFile"
                  accept=".xls, .xlsx, .csv"
                  @change="onFileChange"
                  v-model="bulkFile"
                />
                <small class="text-danger">supported format: .xls or .xlsx</small>
            </b-form-group>
            </b-col>
          </b-row>
       
          <!-- submit and reset -->
      
        </b-form>
      </b-modal>



  </div>
</template>

<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,VBTooltip,BForm,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BFormFile,BFormGroup, BFormCheckbox,BBreadcrumb,BCardBody
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import { ref, onUnmounted } from '@vue/composition-api'
import { POST_API } from "../../../store/actions.type"
import Ripple from 'vue-ripple-directive'

export default {
  components: {

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BForm,
    vSelect,
    BAlert,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BFormFile,
    BFormGroup,
    BFormCheckbox,
    BBreadcrumb,
    BCardBody
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
       tableColumns: [
            { key: 'site', label: 'Project Site', sortable: false , thStyle:  {width: '20%'}},
            { key: 'form_name', label: 'Form Name', sortable: true , thStyle:  {width: '25%'}},
            { key: 'unit_name', label: 'Unit Name', sortable: true , thStyle:  {width: '25%'}},
            { key: 'element_name', label: 'Element Name', sortable: true , thStyle:  {width: '25%'}},
            
            //{ key: 'created_at', label: 'Added On', sortable: true , thStyle:  {width: '15%'}},
            { key: 'actions', label: 'Action', sortable: false , thStyle:  {width: '5%'}},
          ],
        
        items:[],
        isSortDirDesc: true,
        perPageOptions: [10, 20, 50, 100],
        perPage : 10,
        sortBy : 'id',
        webUrl: process.env.VUE_APP_SERVER_URL,
        totalRecords : 0,
        currentPage : 1,
        searchQuery:'',
        from:null,
        to:null,
        showDismissibleAlert:false,
        form:{
          file:'',
          site:'',
          form:'',
        },

        selectedFile:{
          file:null,
          name:'',
        },
        sites:[],
        forms:[],
        error_message:null,
        //showDismissibleAlert:false,
        bulkFile:null,
        sites : [],
        siteData : ''
    }
  },
  methods : {
    dataList(){
      return this.$store.dispatch(POST_API, {
                   data:{
                     page: this.currentPage,
                     keyword: this.searchQuery,
                     rowsPerPage:this.perPage,
                     sortBy:this.sortBy,
                     sortDirection:this.isSortDirDesc,
                     role:this.$store.getters.currentUser.role,
                     om_sites:this.$store.getters.currentUser.om_sites,
                     site:this.siteData ? this.siteData._id : null,
                   },
                   api: '/api/inspection-unit-list'
                })
                .then(() => {
                    if (this.$store.getters.containsErrors) {
                        this.error_message = this.$store.getters.getErrors;
                    } else {
                        this.items = this.$store.getters.getResults.data.docs;
                        this.totalRecords = this.$store.getters.getResults.data.total;
                        this.perPage = this.$store.getters.getResults.data.limit;
                        this.from = this.$store.getters.getResults.data.from
                        this.to = this.$store.getters.getResults.data.to
                        return this.items;
                    }
                });
    },
    filterTable(){
      this.$refs.refUserListTable.refresh();
    },
    variantColor(status){
      if (status == 'active') {
        return 'success';
      }else{
        return 'primary';
      }
    },
    statusChange(item){
      if (item.status == 'active') {
          var msg = 'Are you sure want to inactivate this record?';
          var message='Unit Inactivated Successfully.';
      } 
      else{
          var msg = 'Are you sure want to activate this record?';
          var message='Unit Activated Successfully.';
      }

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: item._id,
                          status:item.status
                        },
                    api:"/api/change-unit-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
             
          })
    },

    deleteItem(id){
      var msg = 'Are you sure want to delete this record?';
      var message='Inspection Unit Deleted Successfully.';

      Swal.fire({
                title: 'Please Confirm',
                text: msg,
                icon: 'warning',
                position: 'top-center',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Yes',
                cancelButtonText: 'No'
              })
            .then(result => {
              if (result.value) {
                  return this.$store.dispatch(POST_API, {
                    data:{
                          id: id,
                          status:'deleted'
                        },
                    api:"/api/change-inspection-unit-status",
                      })
                      .then(() => {
                          if (this.$store.getters.containsErrors) {
                              this.error_message = this.$store.getters.getErrors;
                              this.showAlert();
                          } else {
                            
                            Swal.fire({
                              position: 'center',
                              icon: 'success',
                              title: message,
                              showConfirmButton: false,
                              timer: 1500
                            })
                            
                            this.$refs.refUserListTable.refresh();
                          }
                      });
              }
          })
          .catch(err => {
              
          })
    },

    openModel(item){

      this.showDismissibleAlert = false;

      this.form = {
          file:'',
          site:'',
          form:'',
        };

      this.selectedFile = {
          file:null,
          name:'',
        };

      this.forms =[];
      
      this.$refs['import'].show();
    },
    uploadIU(e){

      e.preventDefault();
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site,
             form:this.form.form,
             selectedfile:this.selectedFile,
             
           },
           api: '/api/import-inspection-units'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                this.$refs.uploadFile.value=null;
                this.selectedFile.file = null;
                this.selectedFile.name = '';
                this.bulkFile = null;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Inspection Unit Imported Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    

                    this.filterTable();
                    this.$refs['import'].hide();
                    ;
                });
                
            }
        });
      
    },
    sampleDownload(){
      var url = process.env.VUE_APP_SERVER_URL + '/samplefiles/inspection_unit_sample.xlsx';
      window.open(url, '_blank');
    },
    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
              role:this.$store.getters.currentUser.role,
              om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                // if (this.sites.length == 1 ) {
                //   this.siteData = this.sites[0];

                //   this.filterTable();
                // }

                var obj = {
                  _id:'all-site',
                  site_name:'All Project Sites',
                  pdf_date_format: 'DD MMM YYYY',
                  pdf_time_format: 'HH:mm'
                }
                this.sites.unshift(obj);

                this.siteData = this.defaultSite(this.sites);
                this.filterTable();

                return this.sites;
            }
        });
    },
    updateForm(){
      this.form.form = '';

      this.allforms();
    },
    allforms(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/all-forms'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.forms = data;
                return this.forms;
            }
        });
    },
    onFileChange(event) {
            
            this.selectedFile.file = null;
            this.selectedFile.name = '';
            var input = event.target;
            
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.file = input.files[0];
                

                if ((this.form.file.type != 'application/vnd.ms-excel') && this.form.file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    
                    this.selectedFile.file = null;
                    this.selectedFile.name = '';
                    
                    this.form.file="";
                    this.bulkFile = null;

                    this.showDismissibleAlert=false;
                    Swal.fire({
                      icon: 'error',
                      title: "Please upload .xls or .xlsx files",
                    })
                } else if (this.form.file.size > 2097152) {

                    this.form.file="";
                    this.selectedFile.file = null;
                    this.selectedFile.name = '';
                    this.showDismissibleAlert=false;
                    this.bulkFile = null;

                    Swal.fire({
                      icon: 'error',
                      title: "Maximum 20 MB files are allowed to upload"
                    })
                } else {
                    reader.onload = (e) => {
                        
                        this.selectedFile.file = e.target.result;
                        this.selectedFile.name = this.form.file.name;

                        this.showDismissibleAlert=false;
                    }
                }
            }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QI & Checklist'
      },{
        to:{name:'forms'},
        text: 'Forms',
      },{
        to:null,
        text: 'Inspection Unit List',
        active:true
      }];
      return item;
    }
  },
  mounted() {
    this.allSites();
  }
  
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
